<template>
  <div class="newMediaOperation">
    <img src="../../assets/product/newMediaOperation/1.png" class="headerimg" />

    <div class="closed">
      <div class="title">邦伲德助力品牌打造人+货+场生态闭环</div>

      <div class="closedlist">
        <div class="closedcard">
          <div class="closedtitle">涨流量</div>
          <div class="closedinfo">广告推广运营</div>
          <div class="closedtxt">全渠道引流获客</div>
        </div>
        <div class="closedcard">
          <div class="closedtitle">涨客户</div>
          <div class="closedinfo">公众号运营</div>
          <div class="closedtxt">拉新+促活+留存策略制定</div>
        </div>
        <div class="closedcard">
          <div class="closedtitle">涨销量</div>
          <div class="closedinfo">SaaS系统运营</div>
          <div class="closedtxt">产品包装+商城+分销裂变</div>
        </div>
        <div class="closedcard">
          <div class="closedtitle">涨效果</div>
          <div class="closedinfo">私域运营</div>
          <div class="closedtxt">会员管理+精准化运营</div>
        </div>
      </div>

      <div class="closedinfolist">
        <div class="closedinfocard">
          <div class="closedinfocardtitle">10万+</div>
          <div class="closedinfocardinfo">KOL资源</div>
        </div>
        <div class="closedinfoline"></div>
        <div class="closedinfocard">
          <div class="closedinfocardtitle">3000+</div>
          <div class="closedinfocardinfo">推广渠道</div>
        </div>
        <div class="closedinfoline"></div>
        <div class="closedinfocard">
          <div class="closedinfocardtitle">5亿+</div>
          <div class="closedinfocardinfo">私域流量池</div>
        </div>
        <div class="closedinfoline"></div>
        <div class="closedinfocard">
          <div class="closedinfocardtitle">350万</div>
          <div class="closedinfocardinfo">服务商家</div>
        </div>
      </div>
    </div>

    <div class="operation">
      <div class="operationlist">
        <div class="operationimg">
          <img src="../../assets/product/newMediaOperation/2.png" alt="" />
        </div>
        <div class="operationcard left">
          <div class="operationtitle">抖音短视频+微信小程序直播代运营</div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">创意新颖的内容呈现</div>
              <div class="infotext">
                传递企业及产品价值，在内容调性与情感层面打动消费者
              </div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">直播全链路运营及全渠道推广</div>
              <div class="infotext">
                全链路直播策划与运营，策略制定、预热推广、执行把控及活动复盘
              </div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">短视频营销</div>
              <div class="infotext">
                帮助企业在内容、渠道、场景、转化四个维度实现有针对性的营销布局
              </div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">创新视频策划及运营推广</div>
              <div class="infotext">
                创新抖音代运营及推广方式，准确触达潜在的短视频用户与品牌受众
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="operation" style="background: #fff">
      <div class="operationlist">
        <div class="operationcard">
          <div class="operationtitle">公众号+小程序+运营</div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">全场景推广</div>
              <div class="infotext">公私域广告展现带来强大流量</div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">商业云打造企业的营销闭环</div>
              <div class="infotext">
                微商城、智慧零售、智营销满足不同量级企业需求
              </div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">短视频营销</div>
              <div class="infotext">
                帮助企业在内容、渠道、场景、转化四个维度实现有针对性的营销布局
              </div>
            </div>
          </div>
          <div>
            <div class="operationblock"></div>
            <div class="operationinfo">
              <div class="infotitle">合作形式灵活</div>
              <div class="infotext">多种套餐模式专属定制</div>
            </div>
          </div>
        </div>
        <div class="operationimg left">
          <img src="../../assets/product/newMediaOperation/3.png" alt="" />
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">解决方案—满足不同场景的经营需求</div>
      <div class="caselist">
        <div class="casecard">
          <div class="casetitle">短视频</div>
          <div class="caseinfo">强社交属性更快连接客户</div>
        </div>
        <div class="casecard">
          <div class="casetitle">邦伲德微商城</div>
          <div class="caseinfo">打通内容+商品短链路营销闭环</div>
        </div>
        <div class="casecard">
          <div class="casetitle">智慧零售</div>
          <div class="caseinfo">助力零收录企业数字化转型</div>
        </div>
        <div class="casecard">
          <div class="casetitle">邦伲德直播</div>
          <div class="caseinfo">背靠巨大流量池，强社交属性</div>
        </div>
        <div class="casecard">
          <div class="casetitle">微站</div>
          <div class="caseinfo">助企业向移动互联网快速转型</div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="title">内容营销—不一样的创意+KOL对点推广</div>
      <div class="caselist">
        <div class="casecard card">
          <div class="casetitle">创意活动+图文</div>
          <div class="caseinfo">短链路直销商品</div>
        </div>
        <div class="casecard card">
          <div class="casetitle">创意手绘+条漫</div>
          <div class="caseinfo">品牌追番式疯传</div>
        </div>
        <div class="casecard card">
          <div class="casetitle">创意短视频+直播</div>
          <div class="caseinfo">零距离用户裂变</div>
        </div>
        <div class="casecard card">
          <div class="casetitle">优质新闻PR撰写投放</div>
          <div class="caseinfo">全媒体覆盖内容营销</div>
        </div>
      </div>
    </div>

    <div class="function">
      <div class="title">功能—交付多元化 自由组合专属方案</div>

      <div class="functionlist">
        <div class="functioncard">
          <div class="functiontitle">引流获客</div>
          <div class="functioninfo">
            <div class="functioninfotitle">广告投放</div>
            <div class="functioninfotext">百度、腾讯等全渠道广告推广</div>
            <div class="functioninfotitle">经营场景</div>
            <div class="functioninfotext">全渠道开店，多渠道引流</div>
            <div class="functioninfotitle">裂变引流</div>
            <div class="functioninfotext">会员分享、引流更多顾客</div>
            <div class="functioninfotitle">直播营销</div>
            <div class="functioninfotext">边播边卖，高效引流获客</div>
          </div>
        </div>
        <div class="functioncard">
          <div class="functiontitle">内容生态</div>
          <div class="functioninfo">
            <div class="functioninfotitle">视频运营</div>
            <div class="functioninfotext">全样式视频制作、推广</div>
            <div class="functioninfotitle">电商运营</div>
            <div class="functioninfotext">微信、公众号等平台内容运营</div>
            <div class="functioninfotitle">网红背书</div>
            <div class="functioninfotext">KOL、网红、大V内容合作</div>
            <div class="functioninfotitle">新闻/PR</div>
            <div class="functioninfotext">新闻PR/软文PR撰写投放</div>
          </div>
        </div>
        <div class="functioncard">
          <div class="functiontitle">私域运营</div>
          <div class="functioninfo">
            <div class="functioninfotitle">用户画像</div>
            <div class="functioninfotext">会员标签分组，促复购</div>
            <div class="functioninfotitle">裂变营销</div>
            <div class="functioninfotext">百种营销方式，拉新促活</div>
            <div class="functioninfotitle">社群运营</div>
            <div class="functioninfotext">社群搭建，推广矩阵拓客导流</div>
            <div class="functioninfotitle">会员运营</div>
            <div class="functioninfotext">搭建私域矩阵，全生命周期运营</div>
          </div>
        </div>
        <div class="functioncard">
          <div class="functiontitle">精准营销</div>
          <div class="functioninfo">
            <div class="functioninfotitle">客服系统</div>
            <div class="functioninfotext">高效管理、随时激励</div>
            <div class="functioninfotitle">商品管控</div>
            <div class="functioninfotext">统一管理，让门店更智慧</div>
            <div class="functioninfotitle">交易洞察</div>
            <div class="functioninfotext">掌握用户行为、卖货更高效</div>
            <div class="functioninfotitle">活动转化</div>
            <div class="functioninfotext">精准营销，业绩持续增长</div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="title">邦伲德公司代运营能力优势凸显</div>
      <div class="title2">获客转化-产品服务交付-用户服务，覆盖每个经营环节</div>

      <div class="advantagelist">
        <div class="advantagecard">微信生态布局</div>
        <div class="advantagecard">渠道布局</div>
        <div class="advantagecard">品牌升级</div>
      </div>

      <div class="advantagelist" style="align-items: center">
        <div class="advantageline">
          <div class="advantagetitle">邦伲德增值运营中心页面</div>
          <div class="advantageinfo">从1到N的全生态布局</div>
        </div>
        <div class="advantageimg">
          <img src="../../assets/product/newMediaOperation/4.png" alt="" />
        </div>
      </div>
    </div>

    <div class="industry">
      <div class="title">覆盖全行业350万用户信任之选</div>
      <div class="industryimg">
        <img src="../../assets/product/newMediaOperation/5.png" alt="" />
      </div>
      <div class="industrylist">
        <div class="industrycard">电商零售</div>
        <div class="industrycard">服装鞋帽</div>
        <div class="industrycard">母婴美妆</div>
        <div class="industrycard">超商百货</div>
        <div class="industrycard">餐饮美食</div>
        <div class="industrycard">生活家居</div>
        <div class="industrycard">教育课程</div>
      </div>
       <div class="industrylist">
        <div class="industrycard">酒店房产</div>
        <div class="industrycard">摄影艺术</div>
        <div class="industrycard">文化旅游</div>
        <div class="industrycard">医疗保险</div>
        <div class="industrycard">直播行业</div>
        <div class="industrycard">汽车</div>
        <div class="industrycard">图书数码</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.industry {
  margin: 140px auto;
  .industryimg {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 28px;
    img {
      width: 1424px;
    }
  }

  .industrylist {
    margin: auto;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 400;
    color: #2f7ffc;
    line-height: 42px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    .industrycard{
      min-width: 120px;
      text-align: center;
    }
  }
}

.advantage {
  padding-top: 93px;
  padding-bottom: 43px;
  background: #f5f7fa;
  .advantagelist {
    margin: auto;
    margin-top: 60px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    .advantageline {
      .advantagetitle {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        line-height: 33px;
      }
      .advantageinfo {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
    .advantageimg {
      img {
        width: 898px;
      }
    }
    .advantagecard {
      width: 397px;
      border: 2px solid #979797;
      height: 117px;
      line-height: 117px;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #616b80;
    }
  }
}

.function {
  margin-bottom: 140px;
  .functionlist {
    margin: auto;
    margin-top: 60px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    .functioncard {
      width: 325px;
      height: 388px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      text-align: center;
      .functiontitle {
        width: 326px;
        height: 82px;
        background: #2f7ffc;
        border-radius: 14px 14px 0px 0px;
        line-height: 82px;
        color: #fff;
      }
      .functioninfo {
        margin-top: 30px;
        font-size: 14px;
        .functioninfotitle {
          font-weight: 500;
          color: #333333;
          line-height: 21px;
          margin-bottom: 10px;
        }
        .functioninfotext {
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.case {
  margin-bottom: 140px;
  .caselist {
    margin: auto;
    margin-top: 60px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    .card {
      width: 325px !important;
    }
    .casecard {
      width: 259px;
      height: 190px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      .casetitle {
        margin-top: 60px;
        font-size: 30px;
        font-weight: 500;
        color: #2f7ffc;
        line-height: 45px;
      }
      .caseinfo {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.operation {
  background: #f5f7fa;
  padding: 80px;
  .operationlist {
    display: flex;
    justify-content: center;
    .left {
      margin-left: 200px;
    }
    .operationimg {
      img {
        width: 473px;
      }
    }
    .operationcard {
      .operationtitle {
        font-size: 36px;
        font-weight: 500;
        line-height: 50px;
        margin-bottom: 40px;
      }
      .operationinfo {
        display: inline-block;
        vertical-align: top;
        .infotitle {
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 33px;
        }
        .infotext {
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
      .operationblock {
        margin-right: 10px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #2f7ffc;
      }
    }
  }
}

.closed {
  margin: 140px auto;
  .closedinfolist {
    margin: auto;
    margin-top: 80px;
    width: 58%;
    display: flex;
    justify-content: space-between;
    .closedinfoline {
      height: 81px;
      width: 1px;
      background: #979797;
      margin-left: 25px;
    }
    .closedinfocard {
      text-align: center;
      .closedinfocardtitle {
        font-size: 24px;
        font-weight: 600;
        color: #2f7ffc;
        line-height: 33px;
      }
      .closedinfocardinfo {
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 36px;
        margin-top: 9px;
      }
    }
  }

  .closedlist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .closedcard {
      width: 325px;
      height: 186px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      text-align: center;
      .closedtitle {
        width: 326px;
        height: 82px;
        background: #2f7ffc;
        border-radius: 14px 14px 0px 0px;
        line-height: 82px;
        color: #fff;
        font-size: 24px;
      }
      .closedinfo {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
      }
      .closedtxt {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}
</style>